import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`How to access KOPE’s native code.`}</p>
    </PageDescription>
    <p>{`The KOPE SDK is currently made up of two `}<a parentName="p" {...{
        "href": "https://www.nuget.org/"
      }}>{`NuGet`}</a>{` packages that can be added to any .NET project.`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`kope.Workflows.SDK.CodeGeneration`}</inlineCode>{` package, which allows developers to write KOPE activities using the same standards and paradigms as writing regular C# methods. This enables a simple process to create, deploy and execute logic in KOPE.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`kope.Workflows.SDK`}</inlineCode>{` package contains the common models required to interact with KOPE concepts and is included in the `}<inlineCode parentName="li">{`kope.SDK.CodeGeneration`}</inlineCode>{` package.`}</li>
    </ul>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Please Note: `}<inlineCode parentName="p">{`kope.Workflows.SDK.CodeGeneration`}</inlineCode>{` uses `}<a parentName="p" {...{
          "href": "https://devblogs.microsoft.com/dotnet/introducing-c-source-generators/"
        }}>{`Source Generators`}</a>{` to build KOPE Activities. This technology is still in preview.
For more information on code generation, see Microsoft’s blog post: `}<a parentName="p" {...{
          "href": "https://devblogs.microsoft.com/dotnet/introducing-c-source-generators/"
        }}>{`Introducing C# Source Generators`}</a>{`.`}</p>
    </InlineNotification>
    <h3>{`Downloading KOPE NuGet Packages`}</h3>
    <p>{`The KOPE Nuget Packages can be accessed `}<a parentName="p" {...{
        "href": "https://dev.azure.com/matterlab/KOPE/_packaging?_a=connect&feed=matterlab.GD"
      }}>{`here`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">{`Under `}<strong parentName="li">{`Connect to feed`}</strong>{`, click the Visual Studio option.`}</li>
      <li parentName="ol">{`Follow the instructions shown.`}
        <br />
      </li>
    </ol>
    <p>{`Here’s a GIF outlining the process as described on the above web page (Note: Values are being copied from the web page, so you’ll need to have this open).`}</p>
    <img {...{
      "src": "/8be96027239080c8534323a989bb9801/kope-nuget-installation.gif",
      "alt": "kope-nuget-installation.gif"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      